import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Flex, Spinner, Heading, ThemeProvider, Paragraph, Link } from "theme-ui";

import { BatchedWebSocketAugmentedWeb3Provider } from "@liquity/providers";
import { LiquityProvider } from "./hooks/LiquityContext";
import { WalletConnector } from "./components/WalletConnector";
import { TransactionProvider } from "./components/Transaction";
import { Icon } from "./components/Icon";
import { getConfig } from "./config";
import theme from "./theme";

import { DisposableWalletProvider } from "./testUtils/DisposableWalletProvider";
import { LiquityFrontend } from "./LiquityFrontend";

if (window.ethereum) {
  // Silence MetaMask warning in console
  Object.assign(window.ethereum, { autoRefreshOnNetworkChange: false });
}

if (process.env.REACT_APP_DEMO_MODE === "true") {
  const ethereum = new DisposableWalletProvider(
    `http://${window.location.hostname}:8545`,
    "0x4d5db4107d237df6a3d58ee5f70ae63d73d7658d4026f2eefd2f204c81682cb7"
  );

  Object.assign(window, { ethereum });
}

// Start pre-fetching the config
getConfig().then(config => {
  // console.log("Frontend config:");
  // console.log(config);
  Object.assign(window, { config });
});


const BASE_CHAIN_ID = 8453;

// 2. Modify the unsupportedNetworkFallback function:
const unsupportedNetworkFallback = (chainId: number) => {
  // If the user is on Base, show a custom message
  if (chainId === BASE_CHAIN_ID) {
    return (
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center"
        }}
      >
        <Heading sx={{ mb: 3 }}>
          <Icon name="exclamation-triangle" /> Meridian Mint on Base is no longer in operation.
        </Heading>
        <Paragraph sx={{ mb: 3 }}>
          If you still have funds on Meridian Mint on Base, please contact the team on{" "}
          <Link
            href="https://t.me/meridianFinance1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </Link>{" "}
          for further assistance.
        </Paragraph>
      </Flex>
    );
  }

  // Otherwise, show the default unsupported network message
  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center"
      }}
    >
      <Heading sx={{ mb: 3 }}>
        <Icon name="exclamation-triangle" /> Meridian is not yet deployed to this network.
      </Heading>
      Please change your network to either Telos, Taraxa or Fuse.
    </Flex>
  );
};



const EthersWeb3ReactProvider: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={provider => new BatchedWebSocketAugmentedWeb3Provider(provider)}>
      {children}
    </Web3ReactProvider>
  );
};

<div style={{
  background: "radial-gradient(circle, white, blue)",
  minHeight: "100vh"
}}>
  {/* Rest of your app */}
</div>

const UnsupportedMainnetFallback: React.FC = () => (

  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      textAlign: "center"
    }}
  >
    <Heading sx={{ mb: 3 }}>
      <Icon name="exclamation-triangle" /> This app is for testing purposes only.
    </Heading>

    <Paragraph sx={{ mb: 3 }}>
      Please change your network to Ropsten, Rinkeby, Kovan, Görli or Kiln.
    </Paragraph>
    {/*
    <Paragraph>
      If you'd like to use the Meridian Protocol on mainnet, please pick a frontend{" "}
      <Link href="https://www.liquity.org/frontend">
        here <Icon name="external-link-alt" size="xs" />
      </Link>
      .
    </Paragraph>
  */}
  </Flex>
);

// 3. Everything else remains the same
const App = () => {
  const loader = (
    <Flex sx={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Spinner sx={{ m: 2, color: "text" }} size="32px" />
      <Heading>Loading...</Heading>
    </Flex>
  );

  return (
    <EthersWeb3ReactProvider>
      <ThemeProvider theme={theme}>
        <div
          style={{
            background: "radial-gradient(circle, #f1f1f3 20%, #f1f1f3 50%, #f1f1f3 100%)",
            minHeight: "100vh"
          }}
        >
          <WalletConnector loader={loader}>
            <LiquityProvider
              loader={loader}
              unsupportedNetworkFallback={unsupportedNetworkFallback}
              unsupportedMainnetFallback={<UnsupportedMainnetFallback />}
            >
              <TransactionProvider>
                <LiquityFrontend loader={loader} />
              </TransactionProvider>
            </LiquityProvider>
          </WalletConnector>
        </div>
      </ThemeProvider>
    </EthersWeb3ReactProvider>
  );
};

export default App;
